import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Process1 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_01.jpg';
import Process4 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_04.jpg';
import Process5 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_05.jpg';
import Process6 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_06.jpg';
import Process7 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_07.jpg';
import Process8 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_08.jpg';
import Process9 from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/BROWN_09.jpg';
import Process1s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_01.jpg';
import Process2s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_02.jpg';
import Process3s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_03.jpg';
import Process4s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_04.jpg';
import Process5s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_05.jpg';
import Process6s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_06.jpg';
import Process7s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_07.jpg';
import Process8s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_08.jpg';
import Process9s from '../../../images/work/24_MID_CENTURY_MODERN/PROCESS/450X280/BROWN_SM_09.jpg';

// const YOUTUBE_URL1 = 'https://www.youtube.com/watch?v=26vO7Dw1zSI';
// const YOUTUBE_URL2 = 'https://www.youtube.com/watch?v=ApuK1mFr_po';
const YOUTUBE_URL1 = 'https://www.youtube.com/embed/26vO7Dw1zSI';
const YOUTUBE_URL2 = 'https://www.youtube.com/embed/ApuK1mFr_po';

const Residence24 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="MID-CENTURY MODERN"
        residenceLocation="CARMEL, CALIFORNIA"
        infoList={[
          'Remodel of existing 2,170 SF single family residence with detached guesthouse. 250 SF addition to a total of 2,420 SF and conversion of the guesthouse to ADU.',
          'Design - 2019-2020',
          'Construction - 2020-2021 (Under construction)',
          'General Contractor: McNamee Construction, Inc.',
          'Structural Engineer: Structures Engineering, Inc.',
        ]}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[
          Process4,
          {
            type: 'YOUTUBE',
            url: YOUTUBE_URL1,
          },
          {
            type: 'YOUTUBE',
            url: YOUTUBE_URL2,
          },
          Process5,
          Process6,
          Process7,
          Process8,
          Process9,
          Process1,
        ]}
        processPhotosSmall={[
          Process4s,
          Process2s,
          Process3s,
          Process5s,
          Process6s,
          Process7s,
          Process8s,
          Process9s,
          Process1s,
        ]}
      />
    </Container>
  </Layout>
);

export default Residence24;
